/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $  = require('jquery');
// For global access to jQuery through `$`
global.$ = $;

// Bootstrap4 with plugins
require('bootstrap');
require('select2');
require('select2/dist/css/select2.css');
require('select2-bootstrap-theme/dist/select2-bootstrap.css');
require('font-awesome/css/font-awesome.css');

// any CSS you require will output into a single css file (app.css in this case)
require('../css/styles.css');

$.fn.select2.defaults.set('theme', 'bootstrap');
